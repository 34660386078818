/* eslint-disable import/no-internal-modules */

import AlertCircleWithRippleEffect from './svg/alert-circle-ripple-effect.svg?icon';
import ChangeStatusWithRippleEffect from './svg/change-status-ripple-effect.svg?icon';
import DiscoverabilityHidden from './svg/discoverability-hidden.svg?icon';
import DiscoverabilityPublic from './svg/discoverability-public.svg?icon';
import SuccessWithRippleEffect from './svg/success-ripple-effect.svg?icon';
import UserAddWithRippleEffect from './svg/user-add-ripple-effect.svg?icon';
import WalletConnect from './svg/wallet-connect.svg?icon';

export const IconAsset = {
  AlertCircleWithRippleEffect,
  ChangeStatusWithRippleEffect,
  DiscoverabilityHidden,
  DiscoverabilityPublic,
  SuccessWithRippleEffect,
  UserAddWithRippleEffect,
  WalletConnect,
};

export type IconAssetName = keyof typeof IconAsset;

export function isIconAssetName(iconAssetName: IconAssetName | string | undefined): iconAssetName is IconAssetName {
  return IconAsset[iconAssetName as IconAssetName] !== undefined;
}
